import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { Observable, concat, combineLatest } from 'rxjs';
import { IEmailTemplate } from 'app/api/email/email-template.model';
import * as _ from 'underscore';
import { ICollection } from 'app/api/common/collection.model';
import { map, share, finalize } from 'rxjs/operators';

@Injectable()
export class SendEmailProcedureService {

    public isLoading = false;
    
    constructor(private api: DashboardApiService) {

    }

    public loadTemplates(includeTemplateId?: string): Observable<IEmailTemplate[]> {

        let timeoutObject = setTimeout(() => this.isLoading = true, 100);

        let observable = this.api.emailTemplates.getTemplates()
            .switchMap(values => {
                if (includeTemplateId == null || _.any(values.items, t => t.id === includeTemplateId)) {
                    return Observable.of<ICollection<IEmailTemplate>>(values).map(v => v.items);
                }

                return combineLatest(Observable.of<ICollection<IEmailTemplate>>(values), this.api.emailTemplates.getTemplatesByIds([includeTemplateId]))
                    .pipe(map(([v1, v2]) => {
                        return v1.items.concat(v2.items);
                    }));
                    
            })
            .pipe(finalize(() => {
                this.isLoading = false;
                clearTimeout(timeoutObject);
            }))
            .pipe(share());

        return observable;
    }
}
