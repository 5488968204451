import { Component, Inject } from '@angular/core';
import * as _ from 'underscore';
import { IUser } from '../../../../../api/users/user.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';
import { CreateOrganizationRequest } from '../../../../../api/organizations/create-organization.request.model';

@Component({
    templateUrl: './create-organization-dialog.component.html',
    styleUrls: ['./create-organization-dialog.component.scss'],
    host: {
        // 'class': 'flex-component'
    }
})
export class CreateOrganizationDialogComponent {
    
    public organizationName = '';
    public nameOk = false;
    public userOk = false;
    public isSaving = false;
    private selectedUser: IUser = null;

    constructor (
        private mdDialogRef: MatDialogRef<CreateOrganizationDialogComponent>,
        private api: DashboardApiService,
        @Inject(MAT_DIALOG_DATA) data: any) {
    }


    public onNameChange(event: Event) {
        this.nameOk = this.organizationName != null && this.organizationName.length >= 1 && this.organizationName.length <= 50;
    }

    public onSelectUser(user: IUser) {
        this.selectedUser = user;
        this.userOk = this.selectedUser != null;
    }

    public save() {
        if (!this.nameOk || !this.userOk) {
            return;
        }
        this.isSaving = true;
        console.log(this.selectedUser);
        this.api.organizations
            .createOrganization(new CreateOrganizationRequest(this.organizationName, this.selectedUser.id))
            .finally(() => this.isSaving = false)
            .subscribe(o => {
                this.mdDialogRef.close({ organization: o });
            }, err => {
                console.error('could not create organization: ', err);
                this.isSaving = false;
            });
    }

    public close() {
        this.mdDialogRef.close();
    }

}
