import { share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ISessionToken, SessionTokenService } from './session-token.service';
import { EnvironmentService } from './environment.service';
import { RouterStates } from './router-states.constant';
import { StateService } from '@uirouter/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs/Rx';
import { observable } from 'rxjs/internal-compatibility';

@Injectable()
export class AuthenticationService {

    private readonly tokenEndpoint: string = null;
    private _renewalObservable: Observable<ISessionToken> = null;
    private _renewalSubscription: Subscription = null;

    constructor(private http: HttpClient, private environmentService: EnvironmentService, private sessionTokenService: SessionTokenService,
                private stateService: StateService) {
        this.tokenEndpoint = environmentService.apiBase + '/oauth/token';
    }

    public beginGoogleLogin() {
        let authRedirectUrl = this.environmentService.siteUrl + '/#/authok';
        let newUrl = this.environmentService.apiBase + '/api/v1/auth/external?provider=Google&redirect_uri=' + encodeURIComponent(authRedirectUrl);
        window.location.href = newUrl;
    }

    public completeSocialLogin(provider: string, token: string): Observable<ISessionToken> {
        if (!token) {
            console.error('Token not provided.');
            return Observable.throw('Token not provided.');
        }

        let body = this.prepareFormPost({
            grant_type: 'external_provider',
            provider: provider,
            token: token
        });

        let self = this;
        return this.http.post<ISessionToken>(this.tokenEndpoint, body, this.getOptions())
            .pipe(map((v) => {
                let updatedToken = <ISessionToken><any>v;
                self.sessionTokenService.token = updatedToken;
                return updatedToken;
            }));
    }

    public login(): Observable<ISessionToken> {

        // Cancel the current renewal if a login is in progress
        if (this._renewalSubscription != null) {
            let dummySubscription = <Subscription>{
                unsubscribe: () => { return; }
            };
            (this._renewalSubscription || dummySubscription).unsubscribe();
        }

        let body = this.prepareFormPost({
            grant_type: 'password',
            username: 'testUserName',
            password: 'testPassword'
        });

        let self = this;
        return this.http.post<ISessionToken>(this.tokenEndpoint, body, this.getOptions())
        .map((resp) => {
            let token = <ISessionToken><any>resp;
            self.sessionTokenService.token = token;
            return token;
        });
    }

    public logout() {
        this.sessionTokenService.token = null;
        this.stateService.go(RouterStates.welcome);
    }

    public refreshToken(): Observable<ISessionToken> {
        if (!this.sessionTokenService.token || !this.sessionTokenService.token.refresh_token) {
            console.error('Refresh token not set.');
            return Observable.throw('Token not provided.');
        }

        let renewalObservable = new Observable<ISessionToken>((subscriber) => {

            let body = this.prepareFormPost({
                grant_type: 'refresh_token',
                refresh_token: this.sessionTokenService.token.refresh_token
            });

            let self = this;
            this._renewalSubscription =
                this.http.post<ISessionToken>(this.tokenEndpoint, body, this.getOptions())
                .map((v) => {
                    return <ISessionToken><any>v;
                })
                .subscribe((data) => {
                    self.sessionTokenService.token = data;
                    subscriber.next(data);
                }, (error) => {
                    this.logout();
                    subscriber.error(error);
                },
                () => {
                    this._renewalSubscription = null;
                    this._renewalObservable = null;
                    subscriber.complete();
                });

        }).pipe(share());

        this._renewalObservable = this._renewalObservable || renewalObservable;
        return this._renewalObservable;
    }

    private getOptions(): any {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        
        return {
            headers: headers
        };
    }

    private prepareFormPost(object: any): string {
        let result = '';
        for (let key in object) {
            if (object.hasOwnProperty(key) && typeof object[key] !== 'object' && typeof object[key] !== 'function') {
                if (result.length > 0) {
                    result = result + '&';
                }
                result = result + encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
            }
        }
        return result;
    }
}
