import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { ICollection } from 'app/api/common/collection.model';
import {of as observableOf,  Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { IMediaTranscodeProfile } from 'app/api/media/media-transcode-profile.model';

@Injectable()
export class TranscodeProfileService {

    public profiles: IMediaTranscodeProfile[];
    public loadingProfiles = true;

    private loadingObservable: Observable<IMediaTranscodeProfile[]>;

    constructor(private api: DashboardApiService) {

    }

    public loadProfiles(): Observable<IMediaTranscodeProfile[]> {

        if (this.profiles == null || this.profiles.length === 0) {
            this.loadingProfiles = true;
        }

        let tmpObservable = this.loadingObservable;
        if (tmpObservable != null) {
            return tmpObservable;
        }

        return this.loadingObservable = this.api.media.getTranscodeProfiles()
            .shareReplay()
            .pipe(finalize(() => {
                this.loadingObservable = null;
                this.loadingProfiles = false;
            }))
            .catch((err, caught) => {
                console.error('Loading quality sets', err);
                return Observable.of<ICollection<IMediaTranscodeProfile>>({ total: 0, items: [] });  
            })
            .map(v => {
                this.profiles = v.items;
                return v.items;
            });

    }

    public loadProfilesIfEmpty(): Observable<IMediaTranscodeProfile[]> {

        if (this.profiles == null || this.profiles.length === 0) {
            return this.loadProfiles();
        } else {
            return observableOf(this.profiles).pipe(take(1));
        }
        
    }

}
