import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SessionTokenService } from './session-token.service';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
    private readonly baseUrl: string = null;
    private readonly baseUrl_staging: string = null;

    constructor (private sessionTokenService: SessionTokenService,
        private authenticationService: AuthenticationService,
        private environmentService: EnvironmentService) {

        this.baseUrl = this.environmentService.apiBase + '/api';
        this.baseUrl_staging = this.environmentService.apiBaseStaging + '/api';
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('oauth/token') || req.url.includes('auth/external')) {
            return next.handle(req);
        }

        let updatedUrl = this.updateUrl(req.url);
        if (req.url === updatedUrl) {
            return next.handle(req);
        }

        req = req.clone({url:updatedUrl});

        if (this.sessionTokenService.hasToken) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.sessionTokenService.token.access_token
                }            
            });
        }

        let methodsRequiringContentType = ['POST', 'PUT', 'PATCH'];
        if (methodsRequiringContentType.indexOf(req.method.toUpperCase()) !== -1 && !req.headers.has('Content-Type') && typeof req.body === 'string') {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            });
        }
        
        let self = this;
        return next.handle(req)
            .pipe(catchError((err: any, caught) => {
                if ( err instanceof HttpErrorResponse ) {
                    switch (err.status) {
                        case 401:
                            return self.handle401(req, next);
                        default:
                            return httpError();
                    }
                } else {
                    return httpError();
                }

                function httpError() {
                    let obs = new Subject<HttpEvent<any>>();
                    obs.error(err);
                    return obs;
                }
            }));
    }

    private handle401(req: HttpRequest<any>, next: HttpHandler) {
        console.log('handle401');
        let self = this;
        return this.authenticationService
            .refreshToken()
            .switchMap((session) => {
                req = req.clone({
                    url: this.updateUrl(req.url), 
                    setHeaders: {
                        Authorization: 'Bearer ' + this.sessionTokenService.token.access_token
                    }            
                });
                return next.handle(req);
            });
    }

    private updateUrl(url: string): string {
        let base = this.baseUrl;
        
        if (url.startsWith('{staging}')) { // URL must always be staging
            url = url.substr('{staging}'.length);
            base = this.baseUrl_staging;
        }
        else if (url.startsWith('{production}')) { // URL must always be production
            url = url.substr('{production}'.length);
            base = this.baseUrl;
        }
        else if (url.startsWith('{api}')) {
            url = url.substr('{api}'.length);            
            if (this.environmentService.useStaging) {
                base = this.baseUrl_staging;
            }
        }
        else {
            return url;
        }

        if (url.startsWith('/')) {
            return base + url;
        }
        else {
            return base + '/' + url;
        }

    }
}
