import { Component } from '@angular/core';
import { UserService } from '../../../core/user.service';

@Component({
    templateUrl: './home.component.html'
})
export class HomeComponent {

    public get userName(): string { return this.userService.user != null ? this.userService.user.name : null; }

    constructor(private userService: UserService) {

    }

}
