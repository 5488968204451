import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { ICollection } from 'app/api/common/collection.model';
import { IMediaQualitySet } from 'app/api/media/media-quality-set.model';
import {of as observableOf,  Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Injectable()
export class QualitySetsService {

    public qualitySets: IMediaQualitySet[];
    public loadingSets = true;

    private loadingObservable: Observable<IMediaQualitySet[]>;

    constructor(private api: DashboardApiService) {

    }

    public loadSets(): Observable<IMediaQualitySet[]> {

        if (this.qualitySets == null || this.qualitySets.length === 0) {
            this.loadingSets = true;
        }

        let tmpObservable = this.loadingObservable;
        if (tmpObservable != null) {
            return tmpObservable;
        }

        return this.loadingObservable = this.api.media.getQualitySets()
            .shareReplay()
            .pipe(finalize(() => {
                this.loadingObservable = null;
                this.loadingSets = false;
            }))
            .catch((err, caught) => {
                console.error('Loading quality sets', err);
                return Observable.of<ICollection<IMediaQualitySet>>({ total: 0, items: [] });  
            })
            .map(v => {
                this.qualitySets = v.items;
                return v.items;
            });

    }

    public loadSetsIfEmpty(): Observable<IMediaQualitySet[]> {

        if (this.qualitySets == null || this.qualitySets.length === 0) {
            return this.loadSets();
        } else {
            return observableOf(this.qualitySets).pipe(take(1));
        }
        
    }

}
