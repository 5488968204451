
import {first,  take } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSort, throwToolbarMixedModesError } from '@angular/material';
import { TeamsService } from './teams.service';
import { PaginatorComponent } from '../../../components/paginator/paginator.component';
import { RouterStates } from '../../../core/router-states.constant';
import { CsvBuilder } from '../../../core/csv-builder';
import { StateService, Transition } from '@uirouter/angular';
import { ITeam } from '../../../api/teams/team.model';
import * as _ from 'underscore';
import { TransitionService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { downloadFileFromString } from 'app/core/blob-downloader';

@Component({
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {

    public get loadingTeams(): boolean { return this.teamsService.loadingData; }
    public get dataSource() { return this.teamsService.dataSource; }
    public displayedColumns = ['team', 'created', 'nextUpdate', 'endDate', 'storage', 'members', 'tags'];
    public searchText: string;
    public generatingCSV = false;

    @ViewChild(MatSort, { static: true }) private sort: MatSort;
    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;

    private debouncedRefresh: Function;
    private stopWatchingTransition: Function;
    private lastSearchText: string;

    constructor(
      private teamsService: TeamsService,
      private stateService: StateService,
      private transitionService: TransitionService,
      private translateService: TranslateService) {
      let self = this;
      this.debouncedRefresh = _.debounce(() => { self.refreshView(self.sort); }, 500);
    }

    public ngOnInit() {
        this.initialize();
    }

    public ngOnDestroy() {
      if (this.stopWatchingTransition) {
        this.stopWatchingTransition();
      }
    }

    public performSearch() {
      if (this.searchText !== this.lastSearchText) {
        this.debouncedRefresh();
      }
    }

    public viewTeam(team: ITeam) {
      this.stateService.go(RouterStates.dashboard_team_view, { teamId: team.id, teamName: team.name });
    }

    public downloadDeletionCandidatesCSV() {
      this.generatingCSV = true;
      this.teamsService.api.teams.getDeletionCandidates()
        .finally(() => this.generatingCSV = false)
        .subscribe(
          csvData => downloadFileFromString(csvData, 'TeamsDeletionCandidates.csv', 'text/csv;charset=utf-8', true),
          err => console.error('error loading deletion candidates for csv export: ', err)
        );
    }

    public downloadListCSV() {
      this.generatingCSV = true;
      let headerKeysPrefix = 'TEAMS.';
      let headerKeys = [
        'TEAM_ID', 'TEAM_NAME', 'ADMIN_NAME', 'ADMIN_EMAIL',
        'TABLE_CREATED', 'TABLE_NEXT_RENEWAL', 'TABLE_END',
        'TABLE_MEMBERS', 'MEMBER_LIMIT',
        'TABLE_TAGS'
      ];
      headerKeys = _.map(headerKeys, k =>  this.translateService.instant(headerKeysPrefix + k));

      let teamSubscription = this.teamsService.dataSource.connect().pipe(
        first())
        .pipe(take(1))
        .finally(() => this.generatingCSV = false)
        .subscribe(
          teams => {
            let maxTags = _.max(teams, t => t.tags.length);
            let helper = new CsvBuilder<ITeam>(headerKeys, t => {
              let created = this.formatCsvDate(t.created);
              let end = this.formatCsvDate(t.plan.storagePlan.endDate);
              let nextRenewal = this.formatCsvDate(t.plan.storagePlan.nextUpdate);

              return [t.id, t.name, t.admin.name, t.admin.email,
                      created, nextRenewal, end, t.plan.storagePlan.renewals,
                      t.members, t.plan.memberLimit, t.tags.join()];
            });
            helper.createCsv(teams, 'teams.csv');
          },
          err => console.error('error loading teams for csv export: ', err)
        );
    }

    private formatCsvDate(value: string): any {
        let utc = false;
        let format = 'YYYY/MM/DD';
        if (!value) { return value; }
        let momentValue = moment(value);
        if (utc) {
            momentValue = momentValue.utc(false);
        }
        return momentValue.format(format || 'L LTS');
    }

    private initialize() {
        this.sort.active = 'created';
        this.sort.direction = 'desc';
        this.sort.sortChange
          .subscribe(
              sort => {
                if (sort.direction === '') {
                  sort.direction = 'asc';
                  this.sort.direction = 'asc';
                }
                this.paginator.pageIndex = 0;
                this.refreshView(sort);
              }
          );

      this.paginator.pageIndex = 0;
      this.paginator.pageSize = 10;

      this.updateFromState();
      this.stopWatchingTransition = this.transitionService.onSuccess({ to: this.stateService.current.name }, (trans: Transition) => {
          this.updateFromState();
      });
      
      this.paginator.onPageChange
        .subscribe(() => {
          this.refreshView(this.sort);
        });
	
      this.refreshView(this.sort);
    }

    private updateFromState() {
      let searchText = this.stateService.params.q;
      let page = this.stateService.params.page;
      let size = this.stateService.params.size;
      let sort = this.stateService.params.sort;
      let direction = this.stateService.params.direction;
      let changes = false;

      if (searchText && searchText !== this.searchText) {
          this.searchText = decodeURIComponent(searchText);
          console.log(this.searchText);
          changes = true;
      }
      
      if (size && size !== this.paginator.pageSize) {
          this.paginator.pageSize = size;
          changes = true;
      }

      if (page && page !== this.paginator.pageIndex) {
          this.paginator.pageIndex = page;
          changes = true;
      }

      if (sort && sort !== this.sort.active) {
        this.sort.active = sort;
      }

      if (direction && direction !== this.sort.direction) {
        this.sort.direction = direction;
      }

      if (changes) {
          this.refreshView(this.sort);
      }
  }

    private refreshView(sort: MatSort) {
      this.lastSearchText = this.searchText;
      let currentSize = this.paginator.pageSize;
      this.teamsService
        .reset(this.lastSearchText, this.convertToSort(sort.active), sort.direction, this.paginator.pageIndex, this.paginator.pageSize)
        .subscribe((i) => {
          this.paginator.itemCount = i.total;
          if (this.paginator.pageSize !== currentSize) {
            this.paginator.pageSize = currentSize;
          }
          console.log('nav to', this.searchText);
          this.stateService.transitionTo(this.stateService.current.name,
            { // if new parameters are added here, add them as dynamic in the routing
              q: this.searchText, 
              page: this.paginator.pageIndex, 
              size: this.paginator.pageSize, 
              sort: sort.active, 
              direction: sort.direction
            });
        });
    }

    private convertToSort(column: string) {
      if (column === 'team') {
        return 'name';
      }
      else if (column === 'created') {
        return 'created';
      }
      else if (column === 'nextUpdate') {
        return 'planNextRenewal';
      }
      else if (column === 'endDate') {
        return 'planEndDate';
      }
      else if (column === 'renewals') {
        return 'planRemainingRenewals';
      }
      else if (column === 'members') {
        return 'memberCount';
      }
      else if (column === 'storage') {
        return 'storageUsedPercentage';
      }
      else {
        return 'created';
      }
    }
}
