import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { SessionTokenService } from './session-token.service';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';
import { HttpClient, HttpEvent, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { last, share } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';
import { downloadFileFromString } from './blob-downloader';

@Injectable()
export class ApiHttp {
    private readonly baseUrl: string = null;
    private readonly baseUrl_staging: string = null;

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
        this.baseUrl = this.environmentService.apiBase + '/api';
        this.baseUrl_staging = this.environmentService.apiBaseStaging + '/api';
    }

    public getTyped<T>(url: string, options?: any): Observable<T> {
        return (<Observable<T>><any>this.http.get<T>(this.updateUrl(url), options)).pipe(share());
    }

    public get(url: string, options?: any): Observable<any> {
        return (<Observable<any>>this.http.get<any>(this.updateUrl(url), options)).pipe(share());
    }

    public getText(url: string, options?: any): Observable<string> {
        return <Observable<string>>(<any>this.http.get<string>(this.updateUrl(url), options)).pipe(share());
    }

    public postTyped<T>(url: string, body: string | FormData, options?: any): Observable<T> {
        return (<Observable<T>><any>this.http.post<T>(this.updateUrl(url), body, options)).pipe(share());
    }

    public post(url: string, body: string | FormData, options?: any): Observable<any> {
        return (<Observable<any>>this.http.post<any>(this.updateUrl(url), body, options)).pipe(share());
    }

    public putTyped<T>(url: string, body: string, options?: any): Observable<T> {
        return (<Observable<T>><any>this.http.put<T>(this.updateUrl(url), body, options)).pipe(share());
    }

    public put(url: string, body: string, options?: any): Observable<any> {
        return (<Observable<any>>this.http.put<any>(this.updateUrl(url), body, options)).pipe(share());
    }

    public patchTyped<T>(url: string, body: string, options?: any): Observable<T> {
        return (<Observable<T>><any>this.http.patch<T>(this.updateUrl(url), body, options)).pipe(share());
    }

    public patch(url: string, body: string, options?: any): Observable<any> {
        return (<Observable<any>>this.http.patch<any>(this.updateUrl(url), body, options)).pipe(share());
    }

    public deleteTyped<T>(url: string, options?: any): Observable<T> {
        return (<Observable<T>><any>this.http.delete<T>(this.updateUrl(url), options)).pipe(share());
    }

    public delete(url: string, options?: any): Observable<any> {
        return (<Observable<any>>this.http.delete<any>(this.updateUrl(url), options)).pipe(share());
    }

    public downloadCsv(url: string): Observable<any> {
        const header = 'Content-Disposition';
        url = this.updateUrl(url);
        return this.http.get<string>(url, <any>{ observe: 'response', responseType: 'text' })
            .pipe(last())
            .map(response => {
                if (response instanceof HttpResponse) {
                    let fileName = 'output.csv';
                    if (response.headers.has(header)) {
                        const headerText = response.headers.get(header);
                        const match  = headerText.match(/(?<=^|;[ ]*)filename=(.*?)(?=$|;)/i);
                        if (match != null) {
                            fileName = match[1];
                        }
                    }

                    downloadFileFromString(response.body, fileName, 'text/csv', true);
                    return true;
                }
                return false;
            })
    }

    private updateUrl(url: string): string {
        let base = this.baseUrl;
        if (url.startsWith('{staging}') || url.startsWith('{production}')) { // URL must always be staging
            return url;
        }
        else {
            return '{api}' + url;
        }
    }
}

class EncoderClass {
    public encodeKey(k: string) { return k; }
    public encodeValue(v: string) { return v; }
}
