
import {map,  take } from 'rxjs/operators';
import { Component }from '@angular/core';
import { StateService, StateParams }from '@uirouter/angular';
import * as _ from 'underscore';
import { DashboardApiService }from '../../../../api/dashboard-api.service';
import { ITask } from '../../../../api/tasks/task.model';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../core/dialog.service';
import * as moment from 'moment';
import { RouterStates } from '../../../../core/router-states.constant';
import { Observable } from 'rxjs';
import { ITaskTemplate } from '../../../../api/tasks/task-template.model';
import { ICollection } from '../../../../api/common/collection.model';
import { TaskType } from '../../../../api/tasks/task-type.enum';
import { toDictionary } from '../../../../core/to-dictionary.function';
import { TaskRequestParameter } from '../../../../api/tasks/task-request-parameter.model';
import { ITaskParameter } from '../../../../api/tasks/task-parameter.model';
import { TaskRequest } from '../../../../api/tasks/task-request.model';

@Component({
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss'],
    host: {
        // 'class': 'view-task-component'
    } // ,
    // encapsulation: ViewEncapsulation.None 
})
export class CreateTaskComponent {
    
    public loading = true;
    public task: ITask = null;
    public selectedTemplate: ITaskTemplate = null;
    public templates: { [id: string]: ITaskTemplate[] } = null;
    public mode: CreateTaskMode = CreateTaskMode.SelectTemplate;
    public taskRequest: TaskRequest = null;

    constructor(
        private api: DashboardApiService,
        private stateService: StateService) {

        this.initialize();
    }

    public templateSelected(template: ITaskTemplate) {
        
        this.selectedTemplate = template;
        this.taskRequest = this.createTaskRequest(this.selectedTemplate);
        this.mode = CreateTaskMode.CreateTask;

    }

    private initialize() {   

        let observables = [];
        observables.push(this.mapTemplatesObservable(this.api.tasks.getTaskTemplates()));
        if (this.stateService.params.srcId != null) {
            observables.push(this.loadSourceTask(this.stateService.params));
        }

        Observable.forkJoin(observables)
            .finally(() => this.loading = false)
            .subscribe(o => {
                this.templates = <{ [id: string]: ITaskTemplate[] }>o[0];
                this.task = null;
                if (o.length === 2) {
                    this.task = <ITask>o[1];
                    let typedTemplates = this.templates[this.task.type] || [];
                    this.selectedTemplate = _.find(typedTemplates, t => t.systemTypeName === this.task.systemTypeName);
                }
                
                if (this.selectedTemplate != null) {
                    this.taskRequest = this.createTaskRequest(this.selectedTemplate, this.task);
                    console.log(this.taskRequest);
                    this.mode = CreateTaskMode.CreateTask;
                }
                else {
                    this.mode = CreateTaskMode.SelectTemplate;
                }
            }, err => console.error('err'));
    }

    private loadSourceTask(stateParams: StateParams): Observable<ITask> {
        if (stateParams.srcId == null) {
            return;
        }
        if (stateParams.srcTask != null) {
            return Observable.of<ITask>(stateParams.srcTask).pipe(take(1));
        }
        else {
            return this.api.tasks.getTaskById(stateParams.srcId).pipe(take(1));
        }
    }

    private mapTemplatesObservable(observable: Observable<ICollection<ITaskTemplate>>): Observable<{ [id: string]: ITaskTemplate[] }> {
        return observable.pipe(
            map<ICollection<ITaskTemplate>, { [id: string]: ITaskTemplate[] }>(v => {
                let dictionary: { [id: string]: ITaskTemplate[] } = {};
                for (let item of v.items) {
                    if (!dictionary.hasOwnProperty(<string>item.type)) {
                        dictionary[item.type] = [];
                    }
                    dictionary[<string>item.type].push(item);
                }
                return dictionary;
            }));
    }

    private createTaskRequest(template: ITaskTemplate, task?: ITask): TaskRequest {
        let existingValues: { [id: string]: ITaskParameter } = {};
        if (task != null) {
            existingValues = toDictionary(task.parameters, p => p.name);
        }

        let parameters: TaskRequestParameter[] = [];
        for (let param of template.parameters) {
            let existing = existingValues[param.name];
            parameters.push(existing != null ? new TaskRequestParameter(existing) : new TaskRequestParameter(param.name, param.type, null));
        }

        let taskRequest = new TaskRequest();
        taskRequest.taskType = template.type;
        taskRequest.taskName = template.systemTypeName;
        taskRequest.parameters = parameters;

        if (task != null && task.initiatorId != null) {
            taskRequest.initiatorId = task.initiatorId;
        }
        return taskRequest;        
    }
}

enum CreateTaskMode {
    SelectTemplate = 'SelectTemplate',
    CreateTask = 'CreateTask'
}
