import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication.service';
import { UserService } from '../../../core/user.service';
import { RouterStates } from '../../../core/router-states.constant';
import { StateService } from '@uirouter/angular';

@Component({
    templateUrl: './authok.component.html'
})
export class AuthOkComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private stateService: StateService) {
    }

  public ngOnInit() {
    let self = this;
    let provider = decodeURIComponent(this.stateService.params.provider);
    let token = decodeURIComponent(this.stateService.params.token);
    if (!provider || !token) {
        this.stateService.go(RouterStates.welcome);
    }
    else {
        this.authenticationService.completeSocialLogin(provider, token)
            .subscribe(
            (result) => {
                self.stateService.go(RouterStates.dashboard_home);
                self.userService.updateUserData()
                    .subscribe();
                },
            error => {
                alert('Social login failed.');
                self.stateService.go(RouterStates.welcome);
            });
    }
  }
}
