import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output, ElementRef, ViewChildren, QueryList, AfterContentChecked }from '@angular/core';
import { ITaskTemplate } from '../../../../../api/tasks/task-template.model';
import * as _ from 'underscore';
import { MatTableDataSource, MatTable, MatInput, MatDialog } from '@angular/material';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../../core/dialog.service';
import { TaskRequest } from '../../../../../api/tasks/task-request.model';
import { TaskParameterType } from '../../../../../api/tasks/task-parameter-type.enum';
import { TaskRequestParameter } from '../../../../../api/tasks/task-request-parameter.model';
import { deepCopy } from '../../../../../core/deep-copy.function';
import { LinkableRefComponent } from '../../../../../components/linkable-ref/linkable-ref.component';
import { IObjectRef, ObjectRef } from '../../../../../api/common/object-ref.model';
import { ObjectTypes } from '../../../../../api/common/object-types.enum';
import { JsonViewerComponent } from '../../../../../components/json-viewer/json-viewer.component';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';
import { StateService } from '@uirouter/angular';
import { RouterStates } from '../../../../../core/router-states.constant';
import { getApiErrors } from '../../../../../api/error/get-api-errors.function';
import { ITaskParameter } from '../../../../../api/tasks/task-parameter.model';
import { IUser } from '../../../../../api/users/user.model';

@Component({
    templateUrl: './create-task-editor.component.html',
    styleUrls: ['./create-task-editor.component.scss'],
    selector: 'create-task-editor'
})
export class CreateTaskEditorComponent implements OnChanges, AfterContentChecked {

    @Input() public taskRequest: TaskRequest;
    @Input() public template: ITaskTemplate;
    public templateParameters: { [id: string]: ITaskParameter } = {};
    public nullParameters: { [id: string]: boolean } = {};
    public editingParameters: { [id: string]: boolean[]} = {};
    public parameterBackups: ParameterBackup[] = [];
    public newParameterField: { [id: string]: string } = {};
    public get initiatorName(): string { return this.initiator == null ? 'Initiator not found' : this.initiator.name; }
    @ViewChildren(MatInput, { read: ElementRef }) public inputElements: QueryList<ElementRef>;
    @ViewChildren(LinkableRefComponent) public linkableRefs: LinkableRefComponent[];
    public isSaving = false;
    public canSave = true;

    private focusTargetInputName: string = null;
    private initiator: IUser = null;

    constructor (
        private api: DashboardApiService,
        private stateService: StateService,
        private dialogService: DialogService,
        private matDialog: MatDialog) {

    }
    
    public ngAfterContentChecked() {
        if (this.focusTargetInputName != null) {
            let targetElement = this.inputElements.find(e => e.nativeElement.name === this.focusTargetInputName); 
            if (targetElement != null) {
                targetElement.nativeElement.select();
                targetElement.nativeElement.focus();
                this.focusTargetInputName = null;
                return;
            }

            let targetLinkableRef = this.linkableRefs.find(r => r != null && r.name != null && r.name === this.focusTargetInputName);
            if (targetLinkableRef != null) {
                targetLinkableRef.focus();
                this.focusTargetInputName = null;
                return;
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        // if (changes.taskRequest.previousValue !== changes.taskRequest.currentValue && changes.taskRequest.currentValue != null) {
        //     this.updateEditing(changes.taskRequest.currentValue);
        // }
        // if (changes.template.previousValue !== changes.template.currentValue && changes.template.currentValue != null) {
        //     for (let parameter of this.template.parameters) {
        //         this.optionalParameters[parameter.name] = !parameter.required;
        //     }
        // }

        if (changes.taskRequest.previousValue !== changes.taskRequest.currentValue || changes.template.previousValue !== changes.template.currentValue) {
            if (this.template != null && this.taskRequest != null) {
                for (let parameter of this.template.parameters) {
                    this.templateParameters[parameter.name] = parameter;
                }
                this.updateEditing(changes.taskRequest.currentValue);
            }
        }
    }

    public blurInitiator(initiatorId: string) {
        this.api.users.getUser(initiatorId)
            .subscribe(u => {
                this.initiator = u;
            }, err => {
                this.initiator = null;
            });
    }

    public addListParameterItem(parameter: TaskRequestParameter, event?: KeyboardEvent) {
        if (event != null && event.keyCode !== 13) {
            return;
        }

        let value = this.newParameterField[parameter.name];
        if (!this.isParameterValueValid(parameter, value)) {
            this.dialogService.showDialog(new DialogCaption('CREATE_TASK.INVALID_PARAMETER', null, { name: parameter.name }));
            return;
        }

        this.newParameterField[parameter.name] = this.getDummyParameterValue(parameter.type, true);
        let data = <any[]>parameter.data;
        data.push(value);
        this.focusTargetInputName = parameter.name + '_new';
    }

    public toggleEditListParameter(parameter: TaskRequestParameter, idx: number, saveChanges?: boolean, event?: KeyboardEvent) {
        if (event != null && event.keyCode !== 13) {
            return;
        }

        let currentState = this.editingParameters[parameter.name][idx];
        let data = <any[]>parameter.data;
        if (!currentState) {
            this.parameterBackups.push(new ParameterBackup(parameter.name, idx, deepCopy(data[idx])));
            this.editingParameters[parameter.name][idx] = true;
            this.focusTargetInputName = parameter.name + '_' + idx;
        }
        else {
            if (saveChanges && !this.isParameterValueValid(parameter, data[idx])) {
                this.dialogService.showDialog(new DialogCaption('CREATE_TASK.INVALID_PARAMETER', null, { name: parameter.name }));
                return;
            }

            let backupIdx = _.findIndex(this.parameterBackups, p => p.name === parameter.name && p.index === idx);
            let backup = this.parameterBackups.splice(backupIdx, 1)[0];
            if (!saveChanges) {
                data[idx] = backup.value;
            }
            this.editingParameters[parameter.name][idx] = false;
        }

        this.canSave = _.chain(_.map(this.taskRequest.parameters, p => this.editingParameters[p.name]))
                        .flatten()
                        .filter(v => v != null)
                        .all(p => p === false)
                        .value();
    }

    public nullChecked(parameter: TaskRequestParameter) {
        if (this.templateParameters[parameter.name].required) {
            return;
        }

        let isNull = this.nullParameters[parameter.name];
        if (isNull) {
            parameter.data = null;
        }
        else {
            parameter.data = this.getDummyParameterValue(parameter.type);
        }
    }

    public deleteListParameter(parameter: TaskRequestParameter, idx: number) {
        let paramIdx = _.findIndex(this.taskRequest.parameters, p => p.name === parameter.name);
        if (paramIdx !== -1) {
            (<any[]>this.taskRequest.parameters[paramIdx].data).splice(idx, 1);
            this.editingParameters[parameter.name].splice(idx, 1);
        }
    }

    public editTrack(index, item) {
        return index;
    }
    

    public editComplexParameter(parameter: TaskRequestParameter) {
        if (parameter == null || parameter.type !== TaskParameterType.Complex) {
            return;
        }

        this.matDialog.open(JsonViewerComponent, {
            width: '800px',
            height: '600px',
            data: { 
                json: JSON.stringify(parameter.data),
                captionKey: 'VIEW_TASK.PARAMETERS_VIEW_CAPTION',
                captionParams: {
                    name: parameter.name
                },
                isEditing: true
            },
            disableClose: true
        }).afterClosed()
          .subscribe(d => {
              if (d != null && d.hasOwnProperty('json')) {
                  parameter.data = JSON.parse(d.json || null);
                  this.nullParameters[parameter.name] = parameter.data == null;
              }
          });
    }

    public discard() {
        this.dialogService.showDialog(
            new DialogCaption('CREATE_TASK.DISCARD_CONFIRM'),
            new DialogYesButton(() => this.stateService.go(RouterStates.dashboard_tasks_list)),
            new DialogNoButton()
        );
    }

    public save() {
        if (this.isSaving || !this.canSave) {
            return;
        }
        this.isSaving = true;

        // check parameters for validity
        for (let parameter of this.taskRequest.parameters) {
            if (!this.isParameterValueValid(parameter, parameter.data)) {
                this.dialogService.showDialog(new DialogCaption('CREATE_TASK.INVALID_PARAMETER', null, { name: parameter.name }));
                this.isSaving = false;
                return;
            }
        }
        
        // check initiatorid for validity
        let ref = new ObjectRef(ObjectTypes.Team, this.taskRequest.initiatorId);
        if (this.taskRequest.initiatorId == null || !ObjectRef.isValid(ref)) {
            this.dialogService.showDialog(new DialogCaption('CREATE_TASK.INVALID_PARAMETER', null, { name: 'InitiatorId' }));
            return;
        }

        this.api.tasks.createTask(this.taskRequest)
            .finally(() => this.isSaving = false)
            .subscribe(t => {
                this.stateService.go(RouterStates.dashboard_tasks_view, { taskId: t.id });
            },
            err => {
                let errors = getApiErrors(err);
                if (errors != null && errors.length !== 0) {
                    this.dialogService.showDialog(new DialogCaption(errors[0].message, false));
                }
                else {
                    this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                }
            });

    }

    private updateEditing(taskRequest: TaskRequest) {
        this.editingParameters = {};
        for (let parameter of _.filter(this.taskRequest.parameters, p => this.isListParameter(p.type))) {
            this.editingParameters[parameter.name] = [];
            this.newParameterField[parameter.name] = this.getDummyParameterValue(parameter.type, true);
            let data: string[] = (parameter.data = parameter.data || []);
            for (let i = 0; i < data.length; ++i) {
                this.editingParameters[parameter.name].push(false);
            }
        }
        for (let parameter of this.taskRequest.parameters) {
            if (this.templateParameters[parameter.name].required && parameter.data == null) {
                parameter.data = this.getDummyParameterValue(parameter.type);
            }
            this.nullParameters[parameter.name] = parameter.data == null;
        }
        if (this.taskRequest.initiatorId == null) {
            this.taskRequest.initiatorId = '0'.repeat(24);
        }
        else {
            this.blurInitiator(this.taskRequest.initiatorId);
        }
    }

    private getDummyParameterValue(parameterType: TaskParameterType, singleItem?: boolean): any {
        if (parameterType === TaskParameterType.ObjectRef) {
            return <IObjectRef>{ type: ObjectTypes.Team, id: '' };
        }
        else if (parameterType === TaskParameterType.ObjectRefList) {
            let item = this.getDummyParameterValue(TaskParameterType.ObjectRef);
            return singleItem ? item : [];
        }
        else if (parameterType === TaskParameterType.Simple) {
            return '';
        }
        else if (parameterType === TaskParameterType.SimpleList) {
            let item = this.getDummyParameterValue(TaskParameterType.Simple);
            return singleItem ? item : [];
        }
        else if (parameterType === TaskParameterType.Complex) {
            return { };
        }
        else {
            throw 'Parameter type not implemented in getDummyParameterValue';
        }
    }

    private isParameterValueValid(parameter: TaskRequestParameter, value: any): boolean {
        let required = this.templateParameters[parameter.name].required;
        if (parameter.type === TaskParameterType.SimpleList) {
            return !required || value != null;
        }
        else if (parameter.type === TaskParameterType.ObjectRefList) {
            return !required || (value != null && ObjectRef.isValid(<IObjectRef>value));
        }
        else if (parameter.type === TaskParameterType.Simple) {
            return !required || value != null;
        }
        else if (parameter.type === TaskParameterType.ObjectRef) {
            return !required || (value != null && ObjectRef.isValid(<IObjectRef>value));
        }
        else if (parameter.type === TaskParameterType.Complex) {
            return !required || value != null;
        }
        else {
            throw 'unknown parameter type in isParameterValueValid method';
        }
    }

    private isListParameter(parameterType: TaskParameterType): boolean {
        return parameterType === TaskParameterType.ObjectRefList || parameterType === TaskParameterType.SimpleList;
    }

    private getObjectProperties(obj: any): string[] {
        if (obj == null) {
            return [];
        }

        return _.filter(Object.keys(obj), p => obj.hasOwnProperty(p));
    }
}

class ParameterBackup {
    constructor (public name: string, public index: number, public value: string) {

    }
}
