import { Component, OnInit } from '@angular/core';
import { SessionTokenService } from '../../core/session-token.service';
import { RouterStates } from '../../core/router-states.constant';
import { StateService } from '@uirouter/angular';

@Component({
    templateUrl: './dashboard-main.component.html'
})
export class DashboardMainComponent implements OnInit {

    constructor(private sessionTokenService: SessionTokenService, private stateService: StateService) {

    }

    // public onLogoutClick() {
    //     return;
    // }

    public ngOnInit() {
        if (!this.sessionTokenService.hasToken) {
            this.stateService.go(RouterStates.welcome);
        }
    }
}
