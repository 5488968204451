import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class SessionTokenService {

    private readonly apiTokenStorageKey = 'api-token';
    private _token: ISessionToken = null;

    constructor (private localStorageService: LocalStorageService) {
        if (this.localStorageService.isStorageAvailable) {
            this._token = <ISessionToken>JSON.parse(this.localStorageService.getItem(this.apiTokenStorageKey));
        }
    }

    public get token(): ISessionToken { return this._token; }

    public set token(token: ISessionToken) {
        this._token = token;
        if (token) {
            this.localStorageService.setItem(this.apiTokenStorageKey, JSON.stringify(token));
        }
        else {
            this.localStorageService.removeItem(this.apiTokenStorageKey);
        }
     }

    public get hasToken(): boolean { return this.token != null && this.token !== undefined; }
}

export interface ISessionToken {
    access_token: string;
    tokenType: string;
    expires_in: number;
    refresh_token: string;
    userName: string;
    provider: string;
    issued: string;
    expires: string;
}
