import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardApiService } from '../api/dashboard-api.service';
import { SessionTokenService } from './session-token.service';
import { IAuthUser } from '../api/auth/auth-user.model';
import { RouterStates } from './router-states.constant';
import { StateService } from '@uirouter/angular';
import * as _ from 'underscore';

@Injectable()
export class UserService {

    public user: IAuthUser = null;
    public get isLoggedIn(): boolean { return this.sessionTokenService.token != null; }
    public userChanged = new EventEmitter<IAuthUser>();

    constructor (private api: DashboardApiService, private sessionTokenService: SessionTokenService, private stateService: StateService) {
        if (this.sessionTokenService.hasToken) {
            this.updateUserData()
                .subscribe();
        }
    }

    public updateUserData(): Observable<IAuthUser> {
        let self = this;
        return new Observable((subscriber) => {

            self.api.auth.getSelf()
            .subscribe(user => {
                self.user = user;
                self.userChanged.emit(self.user);
            },
            error => {
                self.user = null;
                if (error.status === 401) {
                    self.sessionTokenService.token = null;
                    self.stateService.go(RouterStates.welcome);
                    subscriber.error('Token expired.');
                }
                subscriber.error(String(error.status) + ' error.');
            }, () => subscriber.complete());

        });
    }

    public hasRole(role: string): boolean {
        if (this.user == null || this.user.roles == null) {
            return false;
        }
        return _.findIndex(this.user.roles, r => r === role || r.toLowerCase() === role.toLowerCase()) !== -1;
    }
}
